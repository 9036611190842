@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel]  {
  height: 40px;
  border: solid 1px #d9d9d9;
  border-radius: 6px;
  width: 100%;
}

.ck-editor__editable {
  min-height: 300px !important;
}
.score .ck-editor__editable {
  min-height: 200px !important;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
}

li {
  margin: 0.5rem 0;
}
/* 
ol, ul {
  list-style-type: disc !important;
  margin-left: 1.5rem !important;
  list-style: decimal !important;
  margin: unset !important;
  padding: unset !important;
} */

a:hover {
  color: #FECD0E;
}
img:hover {
  cursor: pointer;
}
svg:hover {
  cursor: pointer;
}